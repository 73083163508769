<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :tamanho-form="'xs12 sm8 md6 lg4'"
    :mostra-botao-fechar="false"
  >
    <v-container class="py-0">
      <v-row
        class="xs12"
      >
        <v-col
          v-if="mostraCampoEmail"
          cols="12"
        >
          <span>{{ $vuetify.lang.t('$vuetify.RegisterForm.email') }}</span>
          <v-text-field
            v-model="email"
            class="purple-input"
          />
        </v-col>
        <v-col
          v-if="mostraCampoUsuario"
          cols="12"
        >
          <span>{{ $vuetify.lang.t('$vuetify.padrao_ola') }} <span class="negrito">{{ pessoa.nome }}</span></span>
        </v-col>
        <v-alert
          :value="mostraAlertaUsuarioExistente"
          color="warning"
          transition="scale-transition"
          icon="mdi-account-alert-outline"
        >
          {{ $vuetify.lang.t('$vuetify.RegisterForm.usuarioJaExiste') }}
        </v-alert>
        <v-col
          v-if="mostraCampoSenha"
          cols="12"
        >
          <span>{{ $vuetify.lang.t('$vuetify.LoginForm.senha') }}</span>
          <v-text-field
            v-model="senha"
            :append-icon="mostraSenha ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="[regras.required, regras.min]"
            :type="mostraSenha ? 'text' : 'password'"
            name="input-10-2"
            class="input-group--focused purple-input"
            @click:append="mostraSenha = !mostraSenha"
          />
        </v-col>
        <v-col
          v-if="mostraCampoSenha"
          cols="12"
        >
          <span>{{ $vuetify.lang.t('$vuetify.LoginForm.senha') }}</span>
          <v-text-field
            v-model="confirmarSenha"
            :append-icon="mostraSenha ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="[regras.required, regras.min]"
            :type="mostraSenha ? 'text' : 'password'"
            name="input-10-2"
            class="input-group--focused purple-input"
            @click:append="mostraSenha = !mostraSenha"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-col
      slot="botoes"
      cols="12"
    >
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="$store.state.app.loading"
          :loading="$store.state.app.loading"
          color="success"
          @click.stop="executaAcao()"
        >
          {{ labelBotao }}
          <v-icon dark>
            mdi-login
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-col>
  </crud-form>
</template>

<script>

  import {
    mapMutations
  } from 'vuex'

  import AuthenticationService from '@/services/AuthenticationService'
  import PessoasService from '@/services/PessoasService'

  export default {
    props: {
      esqueceuSuaSenha: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      email: '',
      senha: '',
      confirmarSenha: '',
      pessoa: null,
      usuario: null,
      mostraCampoEmail: true,
      mostraCampoSenha: false,
      mostraCampoUsuario: false,
      mostraAlertaUsuarioExistente: false,
      mostraTextoExistePessoa: false,
      isEditting: false,
      mostraSenha: false
    }),
    computed: {
      labelBotao () {
        if (this.esqueceuSuaSenha || this.mostraAlertaUsuarioExistente || this.mostraTextoExistePessoa) {
          return this.$t('RegisterForm.redefinir')
        } else if (this.mostraCampoSenha) {
          return this.$t('padrao_salvar')
        } else {
          return this.$t('LoginForm.entrar')
        }
      },
      tituloPagina () {
        if (this.esqueceuSuaSenha) {
          return this.$t('padrao_esqueceu_sua_senha')
        } else {
          return this.$t('RegisterForm.registreSe')
        }
      },
      regras: function () {
        let regra = {
          required: valor => !!valor || this.mensagemCampoObrigatorio,
          min: v => v.length >= 8 || this.mensagemMinimoCaracteres
        }
        return regra
      },
      mensagemCampoObrigatorio () {
        return this.$t('padrao_campo_obrigatorio') || 'Não Deu Campo Obrigatório'
      },
      mensagemMinimoCaracteres () {
        return this.$t('LoginForm.validacao_minimo_caracteres') || 'Não Deu Minimo Caracteres'
      }
    },
    created () {
      this.isEditting = false
    },
    methods: {
      ...mapMutations('app', ['setLoading']),
      doLogin () {
        this.setLoading(true)
        setTimeout(() => {
          this.setLoading(false)
        }, 3000)
      },
      executaAcao () {
        if (this.mostraCampoEmail) {
          this.buscaPessoaPorEmail()
        } else if (this.mostraAlertaUsuarioExistente) {
          this.esqueciSenhaPergunta()
        } else if (this.mostraCampoSenha) {
          this.setaSenha()
        } else if (this.mostraTextoExistePessoa) {
          this.setaSenha()
        }
      },
      setaSenha () {
        this.usuario.pwd = this.senha
        AuthenticationService.saveUser(this.usuario)
          .then((usuario) => {
            this.$router.push('/login')
          })
          .catch((erro) => {
            let mensagemErro = this.$t('padrao_ocorreu_um_erro_ultima_operacao') + '\n -> ' + erro

            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: mensagemErro
            })
          })
      },
      buscaPessoaPorEmail () {
        this.setLoading(true)
        this.mostraAlertaUsuarioExistente = false
        this.mostraCampoUsuario = false
        this.mostraCampoSenha = false
        this.mostraTextoExistePessoa = false
        this.mostraCampoEmail = true

        PessoasService.findByParam({
          email: this.email
        }).then((retorno) => {
          if (retorno && retorno.data && retorno.data.rows && retorno.data.rows.length > 0) {
            this.pessoa = retorno.data.rows[0]
            this.mostraCampoEmail = false
            this.mostraCampoUsuario = true
            AuthenticationService.findByEmail(this.email)
              .then((usuario) => {
                if (usuario) {
                  this.usuario = usuario
                  if (usuario.password === 'senha') {
                    this.mostraAlertaUsuarioExistente = true
                  } else {
                    this.mostraAlertaUsuarioExistente = false
                    this.mostraCampoSenha = true
                  }
                }
              })
              .catch((erro) => {
                let mensagemErro = this.$t('padrao_ocorreu_um_erro_ultima_operacao') + '\n -> ' + erro
                if (erro.response.data && erro.response.data.error) {
                  if (erro.response.data.error === 'padrao_registro_nao_encontrado') {
                    this.mostraTextoExistePessoa = true
                    return
                  }
                  mensagemErro = this.$t(erro.response.data.error)
                }

                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: mensagemErro
                })
              })
          }
        }).catch((erro) => {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$t('padrao_ocorreu_um_erro_ultima_operacao') + '\n ->' + erro
          })
        }).finally(() => {
          this.setLoading(false)
        })
      },
      esqueciSenhaPergunta () {
        this.$root.$emit('mostra_dialogo_pergunta', {
          dialogoDescricao: this.$t('RegisterForm.desejaRedefinirSuaSenha'),
          funcaoRetorno: this.esqueciSenhaPerguntaSim
        })
      },
      esqueciSenhaPerguntaSim () {
        AuthenticationService.redefinirSenha(this.email)
          .then(() => {
            this.$root.$emit('mostra_dialogo_info', {
              dialogoTitulo: this.$t('padrao_sucesso'),
              dialogoDescricao: this.$t('RegisterForm.umEmailFoiEnviadoComNovaSenhaPara', this.email)
            })
          })
          .catch((erro) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$t('padrao_ocorreu_um_erro_ultima_operacao') + '\n ->' + erro
            })
          })
      }
    }
  }
</script>
<style>
.negrito {
  font-weight: bold;
}
</style>
